<template>
  <div id="app">
    <div class="viewclass">
      <headers></headers>
      <router-view />
    </div>
    <!-- 弹窗后的状态提示 -->
    <v-message v-if="messageShow"></v-message>
  </div>
</template>

<script>
import 'babel-polyfill'
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState({
      messageShow: (state) => state.Sign.messagesShow,
    }),
  },
};
</script>

<style lang="less">
@import url("./base/commonCSS/common.less");
// @import url("./assets/iconfont/iconfont.css");
#app {
  background: #fff;
  min-width: 1200px;
  height: 100%;
}
.viewclass {
  padding-top: 70px;
  height: 100%;
  box-sizing: border-box;
}
</style>

 